import React from 'react';
import {
  Grid2,
  useTheme
} from "@mui/material";
import { useNavigate } from "react-router-dom"
import { Header } from "../components/Header";
import { Form } from "../components/Form";
import { Campaign } from "../common/types";

interface CreateProps {
  isAuthenticated: boolean;
  currentCampaigns: Campaign[];
  setMode: (mode: "light" | "dark") => void;
}

export function Create(props: CreateProps) {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Grid2 container spacing={3} style={{ padding: "30px" }}>
      <Grid2 container size={12} style={{ justifyContent: "center", alignItems: "center" }}>
        <Header
          isAuthenticated={props.isAuthenticated}
          theme={theme}
          setMode={props.setMode}
        />
      </Grid2>
      <Grid2 container size={12} style={{ justifyContent: "center", alignItems: "center" }}>
        <Form
          currentCampaigns={props.currentCampaigns}
          setMode={props.setMode}
          navigate={navigate}
        />
      </Grid2>
    </Grid2>
  );
}