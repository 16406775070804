import { Campaign } from "../common/types";
import { IPublicClientApplication } from '@azure/msal-browser'
import { getIdToken } from '../common/actionAuth'
import { buildFetchOptions } from '../common/actionOptions';

const backendUrl = 'https://64gqhke7z4.execute-api.eu-central-1.amazonaws.com/prod';

export const addCampaign = async (
  instance: IPublicClientApplication,
  campaign: Campaign
) => {
  const idToken = await getIdToken(instance);
  const options = buildFetchOptions('POST', idToken, campaign);
  await fetch(`${backendUrl}`, options);
}

export const getListOfCampaigns = async (
  instance: IPublicClientApplication,
): Promise<Campaign[]> => {
  const idToken = await getIdToken(instance);
  const options = buildFetchOptions('GET', idToken);
  return fetch(`${backendUrl}`, options)
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
}
