import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { MsalProvider } from '@azure/msal-react'
import { Configuration, PublicClientApplication } from '@azure/msal-browser';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const AZURE_CLIENT_ID = process.env.REACT_APP_AZURE_CLIENT_ID || '';
const AZURE_AUTHORITY = process.env.REACT_APP_AZURE_AUTHORITY || '';
const AZURE_REDIRECT_URL = process.env.REACT_APP_AZURE_REDIRECT_URL || '';
const configuration: Configuration = {
  auth: {
    clientId: AZURE_CLIENT_ID,
    authority: AZURE_AUTHORITY,
    redirectUri: AZURE_REDIRECT_URL,
  },
};

const pca = new PublicClientApplication(configuration);

root.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <App />
    </MsalProvider>
  </React.StrictMode>
);