import React, {useEffect} from "react";
import {
  Backdrop,
  Button,
  FormGroup,
  Grid2,
  MenuItem,
  Paper,
  TextField,
  Typography
} from "@mui/material";
import {
  target_countries,
  target_platforms,
  campaign_types,
  events,
  platforms
} from "../common/constants";
import {
  Campaign,
  DropdownCombination
} from "../common/types";
import { sha256 } from "js-sha256";
import { addCampaign } from "../actions/CampaignActions";
import { getDropdownCombinations } from "../actions/DropdownActions";
import { useMsal } from "@azure/msal-react";
import CircularProgress from "@mui/material/CircularProgress";

function joinObjectValues(obj: object, delimiter = ""): string {
  return Object.values(obj)
    .map(val => {
      if (typeof val === "object") {
        return joinObjectValues(val, delimiter);
      }
      return val !== "" ? val : "0";
    })
    .join(delimiter)
}

interface FormProps {
  setMode: (mode: "light" | "dark") => void;
  currentCampaigns: Campaign[];
  navigate: (path: string, state: object) => void;
}

export function Form(props: FormProps) {
  const { instance } = useMsal();

  const [campaign, setCampaign] = React.useState<Campaign>({
    BaseURL: 'https://www.idealo.de',
    MarketingChannel: '',
    TargetCountry: 'DE',
    TargetMedia: 'ALL',
    CampaignType: '',
    CampaignName: '',
    CampaignID: '',
    Event: '',
    Partner: '',
    Platform: '',
    customParameters: {
      CustomParameter1: '',
      CustomParameter2: '',
      CustomParameter3: '',
      CustomParameter4: '',
      CustomParameter5: ''
    },
    UTMMedium: '',
    UTMSource: '',
    isVisible: true
  } as Campaign);
  const [countrySuffix, setCountrySuffix] = React.useState("de");
  const [selectedValues] = React.useState<DropdownCombination>({
    mkt_channel: '',
    utm_medium: '',
    utm_source: '',
    camp: ''
  });
  const [dropDownCombinations, setDropDownCombinations] = React.useState<DropdownCombination[]>([]);
  const [marketingChannels, setMarketingChannels] = React.useState<string[]>([]);
  const [utmMediums, setUtmMediums] = React.useState<string[]>([]);
  const [utmSources, setUtmSources] = React.useState<string[]>([]);
  const [partners, setPartners] = React.useState<string[]>([]);
  const [cnError, setCNError] = React.useState(false);
  const [urlError, setURLError] = React.useState(false);
  const [dropdownLoadInProgress, setDropdownLoadInProgress] = React.useState(true);
  const [submitInProgress, setSubmitInProgress] = React.useState(false);

  useEffect(() => {
    getDropdownCombinations(instance).then(
      (dropdownCombinations: DropdownCombination[]) => {
        setDropDownCombinations(dropdownCombinations);

        const uniqueValues = dropdownCombinations.reduce((acc, combination) => {
          acc.marketing_channels.add(combination.mkt_channel);
          acc.utm_mediums.add(combination.utm_medium);
          acc.utm_sources.add(combination.utm_source);
          acc.partners.add(combination.camp);
          return acc;
        }, {
          marketing_channels: new Set<string>(),
          utm_mediums: new Set<string>(),
          utm_sources: new Set<string>(),
          partners: new Set<string>()
        });

        setMarketingChannels([...uniqueValues.marketing_channels]);
        setUtmMediums([...uniqueValues.utm_mediums]);
        setUtmSources([...uniqueValues.utm_sources]);
        setPartners([...uniqueValues.partners]);
        setDropdownLoadInProgress(false);
      }
    );
  }, [instance]);

  function handleSelection(changedField: string, selectedValue: string) {
    selectedValues[changedField as keyof DropdownCombination] = selectedValue;
    validateAndUpdateDropdowns(changedField, selectedValue);
  }

  function validateAndUpdateDropdowns(changedField: string, selectedValue: string) {
    let validCombinations = dropDownCombinations.filter(combo => {
      return (
        (selectedValues.mkt_channel ? combo.mkt_channel === selectedValues.mkt_channel : true) &&
        (selectedValues.utm_medium ? combo.utm_medium === selectedValues.utm_medium : true) &&
        (selectedValues.utm_source ? combo.utm_source === selectedValues.utm_source : true) &&
        (selectedValues.camp ? combo.camp === selectedValues.camp : true)
      );
    });

    if (validCombinations.length === 0) {
      clearOtherFields(changedField as keyof DropdownCombination);
      validCombinations = dropDownCombinations.filter(combo => combo[changedField as keyof DropdownCombination] === selectedValue);
    }

    updateDropdownOptions(validCombinations);
  }

  function clearOtherFields(changedField: keyof DropdownCombination) {
    (Object.keys(selectedValues) as (keyof typeof selectedValues)[]).forEach(field => {
      if (field !== changedField) {
        selectedValues[field] = '';
      }
    });
  }

  function updateDropdownOptions(validCombinations: DropdownCombination[]) {
    setMarketingChannels(selectedValues.mkt_channel ? [...new Set(dropDownCombinations.map(combo => combo.mkt_channel))] : [...new Set(validCombinations.map(combo => combo.mkt_channel))]);
    setUtmMediums(selectedValues.utm_medium ? [...new Set(dropDownCombinations.map(combo => combo.utm_medium))] : [...new Set(validCombinations.map(combo => combo.utm_medium))]);
    setUtmSources(selectedValues.utm_source ? [...new Set(dropDownCombinations.map(combo => combo.utm_source))] : [...new Set(validCombinations.map(combo => combo.utm_source))]);
    setPartners(selectedValues.camp ? [...new Set(dropDownCombinations.map(combo => combo.camp))] : [...new Set(validCombinations.map(combo => combo.camp))]);
  }

  function handleSubmit(event: any) {
    setSubmitInProgress(true);
    event.preventDefault();

    campaign.createdAt = new Date().toLocaleString('en-DE', {hour12: false});

    const { BaseURL, Platform, createdAt, isVisible, ic_id, ...rest } = campaign;
    const { UTMSource, UTMMedium, ...rest_2 } = rest;
    const utm_campaign = joinObjectValues(rest_2, "_");
    campaign.ic_id = sha256(joinObjectValues(rest, "_"));

    addCampaign(instance, campaign).then(() => {
      setSubmitInProgress(false);
      props.navigate("/success", { state: { URL:
            BaseURL +
            "?ic_id=" + campaign.ic_id +
            "&utm_campaign=" + encodeURI(utm_campaign) +
            "&utm_source=" + encodeURI(UTMSource) +
            "&utm_medium=" + encodeURI(UTMMedium) +
            (Platform === "" ? "" : "&campaign_id=" + Platform) +
            "&camp=" + encodeURI(campaign.Partner),
          campaign: campaign }});
    });
  }

  return (
    <form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <FormGroup>
        <Grid2 container rowSpacing={3} columnSpacing={5}>
          <Grid2 container size={6} spacing={0} direction="column" style={{paddingTop: "15px"}}>
            <TextField
              label="Marketing Channel"
              variant="outlined"
              required
              select
              margin="normal"
              onChange={(event) => {
                setCampaign({...campaign, MarketingChannel: event.target.value});
                handleSelection('mkt_channel', event.target.value);
              }}
            >
              {marketingChannels.sort().map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Target Country"
              variant="outlined"
              required
              select
              margin="normal"
              onChange={(event) => {
                const newCountrySuffix = event.target.value !== 'UK' ? event.target.value.toLowerCase() : 'co.uk';
                setCountrySuffix(newCountrySuffix);
                setURLError(false);
                setCampaign({
                  ...campaign,
                  TargetCountry: event.target.value,
                  BaseURL: `https://www.idealo.${newCountrySuffix}`
                });
              }}
              slotProps={{ input: { defaultValue: target_countries[1].value } }}
            >
              {target_countries.sort((tc1, tc2) => tc1.label.localeCompare(tc2.label)).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Target Media"
              variant="outlined"
              required
              select
              margin="normal"
              onChange={(event) => {
                setCampaign({...campaign, TargetMedia: event.target.value});
              }}
              slotProps={{ input: { defaultValue: target_platforms[0].value } }}
            >
              {target_platforms.sort((tp1, tp2) => tp1.label.localeCompare(tp2.label)).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Campaign Type"
              variant="outlined"
              required
              select
              margin="normal"
              onChange={(event) => {
                setCampaign({...campaign, CampaignType: event.target.value});
              }}
            >
              {campaign_types.sort((ct1, ct2) => ct1.label.localeCompare(ct2.label)).map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Campaign Name"
              variant="outlined"
              required
              margin="normal"
              error={cnError}
              autoComplete='off'
              helperText={cnError ? "Campaign Name cannot contain '_'" : ""}
              onChange={(event) => {
                if (event.target.value.indexOf('_') !== -1) {
                  setCNError(true);
                  return
                }
                setCNError(false);
                setCampaign({...campaign, CampaignName: event.target.value});
              }}
            />
            <TextField
              label="Event"
              variant="outlined"
              select
              margin="normal"
              onChange={(event) => {
                setCampaign({...campaign, Event: event.target.value});
              }}
            >
              {events.sort((e1, e2) => {
                if (e1.label === "None") return -1;
                if (e2.label === "None") return 1;
                return e1.label.localeCompare(e2.label);
              })
                .map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Partner"
              variant="outlined"
              required
              select
              margin="normal"
              onChange={(event) => {
                setCampaign({...campaign, Partner: event.target.value});
                handleSelection('camp', event.target.value);
              }}
            >
              {partners.sort().map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Platform"
              variant="outlined"
              required
              select
              margin="normal"
              onChange={(event) => {
                const selectedPlatform = platforms.find(
                  (option) => option.id === parseInt(event.target.value)
                );
                setCampaign({ ...campaign, Platform: selectedPlatform!.value });
              }}
            >
              {platforms.sort((p1, p2) => {
                if (p1.label === "None") return -1;
                if (p2.label === "None") return 1;
                return p1.label.localeCompare(p2.label);
              }).map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid2>
          <Grid2 container size={6} spacing={0} direction="column" style={{paddingTop: "15px"}}>
            <TextField
              label="Custom Parameter 1"
              variant="outlined"
              margin="normal"
              autoComplete='off'
              onChange={(event) => {
                setCampaign({
                  ...campaign,
                  customParameters: {...campaign.customParameters, CustomParameter1: event.target.value}
                });
              }}
            />
            <TextField
              label="Custom Parameter 2"
              variant="outlined"
              margin="normal"
              autoComplete='off'
              onChange={(event) => {
                setCampaign({
                  ...campaign,
                  customParameters: {...campaign.customParameters, CustomParameter2: event.target.value}
                });
              }}
            />
            <TextField
              label="Custom Parameter 3"
              variant="outlined"
              margin="normal"
              autoComplete='off'
              onChange={(event) => {
                setCampaign({
                  ...campaign,
                  customParameters: {...campaign.customParameters, CustomParameter3: event.target.value}
                });
              }}
            />
            <TextField
              label="Custom Parameter 4"
              variant="outlined"
              margin="normal"
              autoComplete='off'
              onChange={(event) => {
                setCampaign({
                  ...campaign,
                  customParameters: {...campaign.customParameters, CustomParameter4: event.target.value}
                });
              }}
            />
            <TextField
              label="Custom Parameter 5"
              variant="outlined"
              margin="normal"
              autoComplete='off'
              onChange={(event) => {
                setCampaign({
                  ...campaign,
                  customParameters: {...campaign.customParameters, CustomParameter5: event.target.value}
                });
              }}
            />
            <TextField
              label="UTM Medium"
              variant="outlined"
              select
              required
              margin="normal"
              onChange={(event) => {
                setCampaign({...campaign, UTMMedium: event.target.value});
                handleSelection('utm_medium', event.target.value);
              }}
            >
              {utmMediums.sort().map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="UTM Source"
              variant="outlined"
              select
              required
              margin="normal"
              onChange={(event) => {
                setCampaign({...campaign, UTMSource: event.target.value});
                handleSelection('utm_source', event.target.value);
              }}
            >
              {utmSources.sort().map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              label="Base URL"
              variant="outlined"
              required
              margin="normal"
              error={urlError}
              value={campaign.BaseURL}
              helperText={urlError ? `Base URL must start with 'https://www.idealo.${countrySuffix}'` : ""}
              autoComplete='off'
              onChange={(event) => {
                setURLError(!event.target.value.startsWith(`https://www.idealo.${countrySuffix}`));
                setCampaign({...campaign, BaseURL: event.target.value});
              }}
            />
          </Grid2>
          <Grid2 container size={12} style={{justifyContent: "center"}}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              style={{width: "25%", height: "50px", textTransform: "none"}}
              disabled={cnError || urlError}
            >
              Submit
            </Button>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme: { zIndex: { drawer: number; }; }) => theme.zIndex.drawer + 1 }}
              open={submitInProgress}
            >
              <Paper
                elevation={3}
                style={{ padding: 50, textAlign: "center" }}
              >
                <Typography variant="h6" style={{ textTransform: "none", marginBottom: 30, fontWeight: "bold" }}>
                  Campaign is being created
                </Typography>
                <CircularProgress />
              </Paper>
            </Backdrop>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme: { zIndex: { drawer: number; }; }) => theme.zIndex.drawer + 1 }}
              open={dropdownLoadInProgress}
            >
              <Paper
                elevation={3}
                style={{ padding: 50, textAlign: "center" }}
              >
                <Typography variant="h6" style={{ textTransform: "none", marginBottom: 30, fontWeight: "bold" }}>
                  Dynamic dropdown values are loading
                </Typography>
                <CircularProgress />
              </Paper>
            </Backdrop>
          </Grid2>
        </Grid2>
      </FormGroup>
    </form>
  );
}

