import { Outlet } from 'react-router-dom';
import { useIsAuthenticated } from "@azure/msal-react";
import { SignIn } from "./SignIn";

interface LayoutProps {
  setMode: (mode: "light" | "dark") => void;
}

export const Layout = (props: LayoutProps) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <>
      {isAuthenticated ? <Outlet /> : <SignIn setMode={props.setMode} />}
    </>
  );
}