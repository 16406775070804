export const target_countries = [
  {
    value: "AT",
    label: "AT"
  },
  {
    value: "DE",
    label: "DE"
  },
  {
    value: "FR",
    label: "FR"
  },
  {
    value: "IT",
    label: "IT"
  },
  {
    value: "UK",
    label: "UK"
  },
  {
    label: "ES",
    value: "ES"
  }
];

export const target_platforms = [
  {
    value: "ALL",
    label: "All"
  },
  {
    value: "APP",
    label: "App"
  },
  {
    value: "WEB",
    label: "Web"
  }
]

export const campaign_types = [
  {
    value: "AWR",
    label: "Awareness"
  },
  {
    value: "REA",
    label: "Reach"
  },
  {
    value: "TRA",
    label: "Traffic"
  },
  {
    value: "CVN",
    label: "Conversion"
  },
  {
    value: "INS",
    label: "Install"
  },
  {
    value: "WL",
    label: "Wishlist"
  },
  {
    value: "PA",
    label: "Price Alert"
  }
];

export const events = [
  {
    value: "",
    label: "None"
  },
  {
    value: "PD",
    label: "Prime Day"
  },
  {
    value: "BF",
    label: "Black Friday"
  },
  {
    value: "SD",
    label: "Singles Day"
  },
  {
    value: "FD",
    label: "French Days"
  }
];

export const platforms = [
  {
    id: 1,
    value: "",
    label: "None"
  },
  {
    id: 2,
    value: "{{campaign.id}}",
    label: "Facebook"
  },
  {
    id: 3,
    value: "_CAMPAIGN_ID_",
    label: "TikTok"
  },
  {
    id: 4,
    value: "{campaignid}",
    label: "Google"
  },
  {
    id: 5,
    value: "{campaignid}",
    label: "Pinterest"
  },
  {
    id: 6,
    value: "{{criteo_campaign_id}}",
    label: "Criteo"
  },
  {
    id: 7,
    value: "{CampaignId}",
    label: "Bing"
  },
  {
    id: 8,
    value: "{{campaign.id}}",
    label: "Instagram"
  },
  {
    id: 9,
    value: "{campaign_id}",
    label: "Spotify"
  },
  {
    id: 10,
    value: "{campaignid}",
    label: "YouTube"
  },
  {
    id: 11,
    value: "{{CAMPAIGN_ID}}",
    label: "Reddit"
  }
];

export const typeKeys2ReadableTitles: Map<string, string> = new Map([
  ["MarketingChannel", "Marketing Channel"],
  ["TargetCountry", "Target Country"],
  ["TargetMedia", "Target Media"],
  ["CampaignType", "Campaign Type"],
  ["CampaignName", "Campaign Name"],
  ["CampaignID", "Campaign ID"],
  ["Event", "Event"],
  ["Partner", "Partner"],
  ["Platform", "Platform"],
  ["customParameters", "Custom Parameters"],
  ["UTMMedium", "UTM Medium"],
  ["UTMSource", "UTM Source"],
  ["BaseURL", "Base URL"],
  ["createdAt", "Created At"]
]);
