import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";

export function HomeButton() {
  return (
    <Link to="/" style={{ width: "20%", color: "white", textDecoration: "none" }}>
      <Button
        color="primary"
        variant="contained"
        style={{ width: "100%", height: "50px", textTransform: "none" }}
      >
        Back to Home
      </Button>
    </Link>
  );
}