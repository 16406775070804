import React from 'react';
import {
  Box,
  Button,
  Paper,
  Typography,
  useTheme
} from "@mui/material";
import { useMsal } from "@azure/msal-react";
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeSwitch } from "../components/ThemeSwitch";
import icon from "../assets/idealo-icon.png";

interface SignInProps {
  setMode: (mode: "light" | "dark") => void;
}

export function SignIn(props: SignInProps) {
  const theme = useTheme();
  const { instance, inProgress } = useMsal();

  const handleSignIn = async () => {
    await instance.loginRedirect();
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Paper
        elevation={3}
        style={{ padding: 50, textAlign: "center" }}
      >
        <Typography variant="h4" style={{ textTransform: "none", marginBottom: 30, fontWeight: "bold" }}>
          Welcome to Marketing<br />
          UTM Generator
        </Typography>
        { inProgress === "login" ? <CircularProgress /> :
          <Button
            variant="contained"
            style={{ width: "50%", height: "50px", textTransform: "none", marginBottom: 30 }}
            onClick={handleSignIn}
          >
            <>
              <Box
                component="img"
                sx={{
                  height: 25,
                  width: 25,
                  marginRight: "15px",
                }}
                alt="Idealo Logo"
                src={icon}
              />
              Sign in with Azure AD
            </>
          </Button>
        }
        <br />
        <ThemeSwitch
          theme={theme}
          checked={theme.palette.mode === "dark"}
          onChange={() => props.setMode(theme.palette.mode === "dark" ? "light" : "dark")}
        />
      </Paper>
    </Box>
  );
}