import {
  Button,
  Snackbar,
  TextField} from "@mui/material";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CgCopy } from "react-icons/cg";
import React from "react";

interface URLViewerProps {
  URL: string;
}

export function URLViewer(props: URLViewerProps) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <>
      <h2 style={{marginRight: "50px"}}>URL</h2>
      <TextField
        slotProps={{ input: { disabled: true } }}
        type="text"
        value={props.URL}
        style={{width: "50%", textTransform: "none"}}
      />
      <CopyToClipboard text={props.URL}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleClick}
          style={{width: "200px", height: "50px", textTransform: "none", marginLeft: "20px"}}
        >
          <CgCopy
            size="2.5em"
            style={{height: "100px", textTransform: "none", marginRight: "10px"}}
          />
          Copy to Clipboard
        </Button>
      </CopyToClipboard>
      <Snackbar
        message="URL is copied to clipboard"
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={() => setOpen(false)}
        open={open}
      />
    </>
  );
}

