import {
  AppBar,
  Avatar,
  Box,
  Button,
  Theme,
  Toolbar,
  Typography
} from "@mui/material";
import { ThemeSwitch } from "./ThemeSwitch";
import { getProfile, getProfilePhoto } from "../common/actionAuth";
import { useMsal } from "@azure/msal-react";
import { Profile } from "../common/types";
import logo from "../assets/idealo-logo.png";
import React, { useEffect } from "react";

const AZURE_REDIRECT_URL = process.env.REACT_APP_AZURE_REDIRECT_URL || '';

interface HeaderProps {
  isAuthenticated: boolean;
  theme: Theme;
  setMode: (mode: "light" | "dark") => void;
}

const getNameInitials = (profile: any) => {
  return profile.givenName?.at(0).toUpperCase().concat(profile.surname?.at(0).toUpperCase());
}

export function Header(props: HeaderProps) {
  const { instance } = useMsal();
  const [profile, setProfile] = React.useState<Profile>({} as Profile);
  const [profilePhoto, setProfilePhoto] = React.useState<string>("");

  const logout = async () => {
    const logoutRequest = {
      account: instance.getActiveAccount(),
      postLogoutRedirectUri: AZURE_REDIRECT_URL,
    };
    await instance.logoutRedirect(logoutRequest);
  };

  useEffect(() => {
    getProfilePhoto(instance).then((photo) => {
      setProfilePhoto(URL.createObjectURL(photo));
    });
    getProfile(instance).then((profile) => {
      setProfile(profile);
    });
  }, [instance]);

  return (
    <AppBar position="static">
      <Toolbar style={{ paddingLeft: "0px" }}>
        <Box
          component="img"
          sx={{
            height: 65,
            width: 120,
            marginRight: "30px",
          }}
          alt="Idealo Logo"
          src={logo}
        />
        <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
          Marketing UTM Generator
        </Typography>
        <ThemeSwitch
          theme={props.theme}
          checked={props.theme.palette.mode === "dark"}
          onChange={() => props.setMode(props.theme.palette.mode === "dark" ? "light" : "dark")}
          sx={{marginRight: "20px"}}
        />
        {props.isAuthenticated && (profile || profilePhoto) &&
          <>
            {profilePhoto ? <Avatar src={profilePhoto}/> : <Avatar>{getNameInitials(profile)}</Avatar>}
            <Button color="inherit" onClick={logout} sx={{marginLeft: "20px"}}>Logout</Button>
          </>
        }
      </Toolbar>
    </AppBar>
  );
}

//
