import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";

export function CreateCampaignButton() {
  return (
    <Link to="create" style={{width: "20%", color: "white", textDecoration: "none"}}>
      <Button
        variant="contained"
        style={{width: "100%", height: "50px", textTransform: "none"}}
      >
        Create New Campaign
      </Button>
    </Link>
  );
}