import React, {
  useMemo,
  useState
} from 'react';
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";
import {
  createTheme,
  CssBaseline,
  PaletteMode,
  ThemeProvider
} from "@mui/material";
import { useIsAuthenticated } from '@azure/msal-react';
import { Create } from "./pages/Create";
import { Home } from "./pages/Home";
import { Layout } from "./pages/Layout";
import { Success } from "./pages/Success";
import { useLocalStorage } from "./common/localStorage";
import { Campaign } from "./common/types";

function App()  {
  const isAuthenticated = useIsAuthenticated();
  const [currentCampaigns, setCurrentCampaigns] = useState<Campaign[]>([]);
  const [mode, setMode] = useLocalStorage<PaletteMode>("color-scheme", "light");

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        }
      }),
    [mode]
  );

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout setMode={setMode}/>}>
            <Route index
              element={
                <Home
                  isAuthenticated={isAuthenticated}
                  currentCampaigns={currentCampaigns}
                  setCurrentCampaigns={setCurrentCampaigns}
                  setMode={setMode}
                />
              }
            />
            <Route
              path="create"
              element={
                <Create
                  isAuthenticated={isAuthenticated}
                  currentCampaigns={currentCampaigns}
                  setMode={setMode}
                />
              }
            />
            <Route
              path="success"
              element={
                <Success
                  isAuthenticated={isAuthenticated}
                  setMode={setMode}
                />
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;