import {
  Grid2,
  Paper,
  Typography
} from "@mui/material";
import { typeKeys2ReadableTitles } from "../common/constants";
import { Campaign } from "../common/types";
import React from "react";


interface SummaryProps {
  campaign: Campaign
}

export function Summary(props: SummaryProps) {
  return (
    <Paper
      square={false}
      elevation={3}
      style={{
        width: 1000,
        height: "100%",
        textAlign: "center",
        paddingBottom: "25px"
      }}
    >
      <h2>Summary</h2>
      <Grid2 size={12} container>
        <Grid2 container size={5} direction="column" style={{ alignItems: "flex-start", marginLeft: "50px" }}>
          {Object
            .entries(props.campaign)
            .slice(0, 7)
            .filter(([key, _]) => {
              return ['ic_id', 'isVisible'].indexOf(key) === -1;
            })
            .map(([key, value]) => {
              if (key === "customParameters") {
                value = Object.values(value).join(", ");
              }
              if (value === "") {
                return null;
              }
              return (
                <Typography variant="h6" style={{ marginBottom: "10px" }}>
                  <li>{typeKeys2ReadableTitles.get(key) + ": " + value}</li>
                </Typography>
              );
            })
          }
        </Grid2>
        <Grid2 container size={5} direction="column" style={{ alignItems: "flex-start", marginLeft: "100px" }}>
          {Object
            .entries(props.campaign)
            .slice(7, -1)
            .filter(([key, _]) => {
              return ['ic_id', 'isVisible'].indexOf(key) === -1;
            })
            .map(([key, value]) => {
              if (key === "customParameters") {
                value = Object.values(value).filter(v => { return v !== "" }).join(", ");
              }
              if (value === "") {
                return null;
              }
              return (
                <Typography variant="h6" style={{ marginBottom: "10px" }}>
                  <li>{typeKeys2ReadableTitles.get(key) + ": " + value}</li>
                </Typography>
              );
            })
          }
        </Grid2>
      </Grid2>
    </Paper>
  );
}

