import { AgGridReact } from "ag-grid-react";
import { ColDef } from "ag-grid-community";
import { Campaign } from "../common/types";
import { Theme } from "@mui/material";
import React, { useState } from "react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

interface DataDisplayProps {
  currentCampaigns: Campaign[];
  loading: boolean;
  theme: Theme;
}

export function DataDisplay(props: DataDisplayProps) {
  const [colDefs] = useState<ColDef<Campaign>[]>([
    { field: "MarketingChannel", headerName: "Channel", filter: true, filterParams: { buttons: ['reset'] }, sort: "asc", sortIndex: 0 },
    { field: "TargetCountry", headerName: "Country" },
    { field: "TargetMedia", headerName: "Media" },
    { field: "CampaignType", headerName: "Type" },
    { field: "CampaignName", headerName: "Name" },
    { field: "Event" },
    { field: "Partner" },
    { field: "Platform", headerName: "Campaign ID" },
    { field: "UTMMedium", headerName: "UTM Medium" },
    { field: "UTMSource", headerName: "UTM Source" },
    {
      field: "createdAt", headerName: "Created At", comparator:
        (valueA, valueB) => {
          const [date, time] = valueA.split(", ");
          const [day, month, year] = date.split("/");
          const [hour, minute, second] = time.split(":");
          const dateA = new Date(`${year}-${month}-${day}T${hour}:${minute}:${second}`).getTime();
          const [date2, time2] = valueB.split(", ");
          const [day2, month2, year2] = date2.split("/");
          const [hour2, minute2, second2] = time2.split(":");
          const dateB = new Date(`${year2}-${month2}-${day2}T${hour2}:${minute2}:${second2}`).getTime();
          return dateA - dateB;
        },
      sortIndex: 1, sort: "desc", minWidth: 175
    }
  ]);

  const defaultColDef: ColDef = {
    flex: 1,
    resizable: false,
    suppressMovable: true,
  };

  return (
    <div
      className={
        props.theme.palette.mode === "dark" ? "ag-theme-alpine-dark" : "ag-theme-alpine"
      }
      style={{width: "100%", height: "100%"}}
    >
      <AgGridReact
        rowData={props.currentCampaigns}
        columnDefs={colDefs}
        defaultColDef={defaultColDef}
        rowHeight={35}
        domLayout='autoHeight'
        pagination={true}
        paginationPageSize={10}
        paginationPageSizeSelector={[10, 25, 50, 100]}
        suppressDragLeaveHidesColumns
        loading={props.loading}
      />
    </div>
  );
}

